import React from "react";
import styled from "styled-components";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 500px;
  max-width: 100%;
  background-color: #fff;
`;

const FeedbackTitle = styled.h1`
margin: 8px;
`;

const FeedbackText = styled.p`
margin: 8px;
`;

const FeedbackButton = styled.a`
  width: 200px;
  padding: 8px;
  margin: 8px;
  background-color: #3a36ff;
  color:  #fff;
  border-radius: 16px;
  font-size: 16px;
  text-decoration: none;
`;

const VerifyEmailError = () => {
  return (
    <>
      <Navbar />
      <FeedbackContainer>
        <FeedbackTitle>Verify Email Error</FeedbackTitle>
        <FeedbackText>Something went wrong try again!</FeedbackText>
        <FeedbackButton href={`posgez://`}>Open App</FeedbackButton>
      </FeedbackContainer>
      <Footer />
    </>
  );
}; 

export default VerifyEmailError